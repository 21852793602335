<template>
  <!-- <Navbar /> -->
  <router-view />
  <Footer />
  <Cookies />
</template>
<script setup>
import { defineAsyncComponent } from "vue";

const Footer = defineAsyncComponent(() => import("./components/footer/FooterPrincipal.vue"));
const Navbar = defineAsyncComponent(() => import("./components/navbar/Navbar.vue"));
const Cookies = defineAsyncComponent(() => import("./components/mensajeCookies/Cookies.vue"));
</script>