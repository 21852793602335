import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      title: `Home`,
      metaTags: [
        {
          name: "description",
          content:
            "Alebat es una agencia especializada en el sector salud que transforma y mejora el rendimiento de tu organización.",
        },
        {
          property: "og:description",
          content:
            "Alebat es una agencia especializada en el sector salud que transforma y mejora el rendimiento de tu organización.",
        },
        {
          name: "keywords",
          content: "Home alebat, alebat",
        },
        {
          name: "author",
          content: "Alebat",
        },
      ],
    },
  },
  // {
  //   path: '/creativity',
  //   name: 'Creativity',
  //   component: () => import('../views/CreativityView.vue'),
  //   meta: {
  //     title: `Creativity`,
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'En Alebat somos expertos en estrategia de marca, branding y marketing digital, especializados en el sector salud.'
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'En Alebat somos expertos en estrategia de marca, branding y marketing digital, especializados en el sector salud.'
  //       },
  //       {
  //         name: 'keywords',
  //         content: 'Creativity alebat, Creativity,'
  //       },
  //       {
  //         name: 'author',
  //         content: 'Alebat'
  //       }
  //     ]
  //   }
  // },
  // {
  //   path: '/learn',
  //   name: 'Learn',
  //   component: () => import('../views/LearnView.vue'),
  //   meta: {
  //     title: `Learn`,
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'Con nuestro método Learn, tu equipo y tus clientes se formarán con contenidos que permitirán aumentar tus resultados.'
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'Con nuestro método Learn, tu equipo y tus clientes se formarán con contenidos que permitirán aumentar tus resultados.'
  //       },
  //       {
  //         name: 'keywords',
  //         content: 'Learn alebat, alebat, Learn'
  //       }
  //     ]
  //   }
  // },
  // {
  //   path: '/data',
  //   name: 'Data',
  //   component: () => import('../views/DataView.vue'),
  //   meta: {
  //     title: `Data`,
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'En Alebat contamos con la tecnología para mejorar la toma de decisiones en tu organización con datos e inteligencia artificial.'
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'En Alebat contamos con la tecnología para mejorar la toma de decisiones en tu organización con datos e inteligencia artificial.'
  //       },
  //       {
  //         name: 'keywords',
  //         content: 'Data alebat, alebat, Data'
  //       },
  //       {
  //         name: 'author',
  //         content: 'Alebat'
  //       }
  //     ]
  //   }
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import('../views/AboutView.vue'),
  //   meta: {
  //     title: `About`,
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'Somos Alebat, especialistas en el sector salud con más de 20 años de experiencia. Nuestro objetivo: impulsar tus resultados y rentabilidad.'
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'Somos Alebat, especialistas en el sector salud con más de 20 años de experiencia. Nuestro objetivo: impulsar tus resultados y rentabilidad.'
  //       },
  //       {
  //         name: 'keywords',
  //         content: 'About alebat, alebat, About'
  //       },
  //       {
  //         name: 'author',
  //         content: 'Alebat'
  //       }
  //     ]
  //   }
  // },
  // // {
  // //   path: '/clinics',
  // //   name: 'Clinics',
  // //   component: () => import('../views/ClinicsView.vue'),
  // //   meta: {
  // //     title: `Impulsa`,
  // //     metaTags: [
  // //       {
  // //         name: 'description',
  // //         content: 'Impulsa tu negocio con Alebat, especialistas en tecnología, innovación y pensamiento estratégico para clínicas del sector salud.'
  // //       },
  // //       {
  // //         property: 'og:description',
  // //         content: 'Impulsa tu negocio con Alebat, especialistas en tecnología, innovación y pensamiento estratégico para clínicas del sector salud.'
  // //       },
  // //       {
  // //         name: 'keywords',
  // //         content: 'Impulsa alebat, alebat, impulsa'
  // //       },
  // //       {
  // //         name: 'author',
  // //         content: 'Alebat'
  // //       }
  // //     ]
  // //   }
  // // },
  // // {
  // //   path: '/oficinas',
  // //   name: 'Oficinas',
  // //   component: () => import('../views/OficinasView.vue'),
  // //   meta: {
  // //     title: `Oficinas`,
  // //     metaTags: [
  // //       {
  // //         name: 'description',
  // //         content: 'Oficinas'
  // //       },
  // //       {
  // //         property: 'og:description',
  // //         content: 'Oficinas'
  // //       },
  // //       {
  // //         name: 'keywords',
  // //         content: 'Oficinas alebat agencia, alebat agencia, alebat, agencia'
  // //       },
  // //       {
  // //         name: 'author',
  // //         content: 'Alebat'
  // //       }
  // //     ]
  // //   }
  // // },
  // {
  //   path: '/work',
  //   name: 'Work',
  //   component: () => import('../views/WorkView.vue'),
  //   meta: {
  //     title: `Work`,
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'Descubre algunos de nuestros trabajos que hemos realizado para diferentes empresas e instituciones del sector Salud.'
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'Descubre algunos de nuestros trabajos que hemos realizado para diferentes empresas e instituciones del sector Salud.'
  //       },
  //       {
  //         name: 'keywords',
  //         content: 'Work alebat, alebat, Work'
  //       },
  //       {
  //         name: 'author',
  //         content: 'Alebat'
  //       }
  //     ]
  //   }
  // },
  {
    path: "/kit-digital",
    name: "Kit Digital",
    component: () => import("../views/KitDigitalView.vue"),
    meta: {
      title: `Kit Digital`,
      metaTags: [
        {
          name: "description",
          content: "Kit Digital",
        },
        {
          property: "og:description",
          content: "Kit Digital",
        },
        {
          name: "keywords",
          content:
            "Kit Digital alebat agencia, alebat agencia, alebat, agencia",
        },
        {
          name: "author",
          content: "Alebat",
        },
      ],
    },
  },
  // {
  //   path: '/politica-de-privacidad',
  //   name: 'Politica De Privacidad',
  //   component: () => import('../views/PoliticaDePrivacidadView.vue'),
  //   meta: {
  //     title: `Politica de Privacidad`,
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'Pagina principal de Politica de Privacidad. Aquí encontrarás cómo gestionamos el tratamiento de datos personales.'
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'Pagina principal de Politica de Privacidad. Aquí encontrarás cómo gestionamos el tratamiento de datos personales.'
  //       }
  //     ]
  //   }
  // },
  // {
  //   path: '/cookies',
  //   name: 'Cookies',
  //   component: () => import('../views/CookiesView.vue'),
  //   meta: {
  //     title: `Politica de cookies`,
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'Pagina principal de politica de cookies. Aquí encontraras información politica de cookies.'
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'Pagina principal de politica de cookies. Aquí encontraras información politica de cookies.'
  //       }
  //     ]
  //   }
  // },
  // {
  //   path: '/acerca-de',
  //   name: 'Acerca de',
  //   component: () => import('../views/AcercaDeView.vue'),
  //   meta: {
  //     title: `Acerca de`,
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'Pagina principal de Acerca de. Aquí encontraras información acerca de.'
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'Pagina principal de Acerca de. Aquí encontraras información acerca de.'
  //       }
  //     ]
  //   }
  // },
  // {
  //   path: '/gracias',
  //   name: 'gracias',
  //   component: () => import('../views/Gracias.vue'),
  //   meta: {
  //     title: `Gracias`,
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'Gracias'
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'Gracias'
  //       }
  //     ]
  //   }
  // },
  // {
  //   path: '/:pathMatch(.*)',
  //   name: '404',
  //   component: () => import('../views/404_View.vue'),
  //   meta: {
  //     title: `404`,
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'Pagina error 404.'
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'Pagina error 404.'
  //       }
  //     ]
  //   }
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  // Borramos todas las etiquetas meta que son de la antigua pagina
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );
  //Comprobamos que tiene puesta las metaTags
  if (!to.meta.metaTags) return next();

  to.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      // Usamos esto para rastrear qué metaetiquetas creamos para no interferir con otras.
      tag.setAttribute("data-vue-router-controlled", "");
      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
